import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Subheader from '../components/Subheader';

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        date?: string;
      };
    };
  };
}
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}: Props) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { html } = markdownRemark;
  return (
    <Layout>
      <SEO title="Settings" />
      <Subheader crumbs={[]} />
      <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
    }
  }
`;
